import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import MeineAntwortButton from "../components/MeineAntwortButton";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";

function Navigation() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const { t } = useTranslation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const linkStyle = {
    fontFamily: "Quicksand, sans-serif",
    textTransform: "capitalize",
    fontSize: 16,
    color: "inherit", // Or whatever your default color is
    textDecoration: "none", // Default non-active style
    "&:hover": {
      textDecoration: "underline",
    },
  };

  const activeLinkStyle = {
    ...linkStyle,
    textDecoration: "underline", // Active link style
  };

  const getLinkStyle = (path) =>
    location.pathname === path ? activeLinkStyle : linkStyle;

  const isActive = (path) => location.pathname === path;

  const listItemStyle = {
    fontFamily: "Quicksand, sans-serif",
    fontSize: "18px", // adjust the size as needed
    color: "inherit", // replace with actual color if needed
    textAlign: "center",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
    width: "100%", // ensure the link takes the full width for better click area
  };

  // Updated navigation items using translations
  const navigationItems = [
    { text: "Anne & Fabian", path: "/" },
    { text: "Location", path: "/location" },
    { text: t("header_schedule"), path: "/ablauf" },
    { text: t("header_accommodation"), path: "/übernachten" },
    { text: t("header_request"), path: "/zu-beachten" },
  ];

  const drawer = (
    <Box
      sx={{
        width: "100%",
        textAlign: "center",
        height: "100vh",
        backgroundColor: "#e2e8f0",
      }}
      role="presentation"
    >
      <List>
        <ListItem sx={{ justifyContent: "flex-end" }}>
          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
        </ListItem>
        {["/", "/location", "/ablauf", "/übernachten", "/zu-beachten"].map(
          (path, index) => (
            <ListItemButton
              key={path}
              onClick={handleDrawerToggle}
              sx={{
                justifyContent: "center",
                ...(isActive(path) ? { textDecoration: "underline" } : {}),
              }}
              component={Link}
              to={path}
            >
              <ListItemText
                primary={
                  [
                    "Anne & Fabian",
                    "Location",
                    t("header_schedule"),
                    t("header_accommodation"),
                    t("header_request"),
                  ][index]
                }
                primaryTypographyProps={{ sx: listItemStyle }}
              />
            </ListItemButton>
          )
        )}
        <ListItem sx={{ justifyContent: "center", mt: 4 }}>
          <MeineAntwortButton handleDrawerToggle={handleDrawerToggle} />
        </ListItem>
        <ListItem sx={{ justifyContent: "center", mt: 4 }}>
          <LanguageSwitcher />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        backgroundColor: "transparent !important", // Force transparency
        color: "inherit",
        boxShadow: "none !important",
        padding: 0,
        margin: 0,
        ...(isMobile ? {} : { borderBottom: 1, borderColor: "divider" }),
      }}
    >
      <Toolbar>
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            position: "absolute",
            right: 18,
            ...(isMobile ? {} : { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "100%",
            },
          }}
        >
          {drawer}
        </Drawer>
        {!isMobile && (
          <Box
            sx={{
              display: "flex",
              color: "black",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {navigationItems.map((item, index) => (
              <Typography sx={{ mx: 4 }} key={index}>
                <Link to={item.path} style={getLinkStyle(item.path)}>
                  {item.text}
                </Link>
              </Typography>
            ))}
            <MeineAntwortButton style={getLinkStyle("/meine-antwort")} />
            <LanguageSwitcher />
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navigation;
