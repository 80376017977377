import React from "react";
import { Box } from "@mui/material";
import SublineInfo from "../components/SublineInfo";
import SectionTitle from "../components/SectionTitle";
import ContactForm from "../components/ContactForm";
import { useTranslation } from "react-i18next";

function MeineAntwort() {
  const { t } = useTranslation();
  const rsvp_subline = t("rsvp_subline");
  const rsvp_headline = t("rsvp_headline");

  return (
    <Box
      sx={{
        flexGrow: 1,

        px: { xs: 1, md: 12 }, // Adjust padding as needed
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SublineInfo title={rsvp_subline} />
      <SectionTitle title={rsvp_headline} />
      <ContactForm />
      {/* Additional content can be added here if needed */}
    </Box>
  );
}

export default MeineAntwort;
