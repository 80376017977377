import React from "react";
import { Box } from "@mui/material";
import SublineInfo from "../components/SublineInfo";
import SectionTitle from "../components/SectionTitle";
import AddressSection from "../components/AddressSection";
import DescriptionSection from "../components/DescriptionSection";
import MyGoogleMap from "../components/MyGoogleMap";
import SectionDivider from "../components/SectionDivider";
import { useTranslation } from "react-i18next";

function Location() {
  const { t } = useTranslation();

  const location_subline = t("location_subline");
  const location_address = t("location_address");

  const location_note = t("location_note");
  const location_note_description = t("location_note_description");

  return (
    <Box
      sx={{
        flexGrow: 1,
        px: { xs: 1, md: 12 }, // Adjust padding as needed
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SublineInfo title={location_subline} />
      <SectionTitle
        title="Orangerie Schloß Bekond"
        imageSrc="/Orangerie-Schloss-Bekond.webp"
      />
      <AddressSection
        firstButtonText="Google Maps"
        addressTitle={location_address}
        street="Am Weiher 15"
        zipCode="54340 Bekond"
        firstLink="https://maps.app.goo.gl/Dmuyc8vDpHChb7xx7"
      />
      <SectionDivider />
      <DescriptionSection
        subTitle={location_note}
        description={location_note_description}
      />
      <SectionDivider />

      <MyGoogleMap />
      {/* Additional content can be added here if needed */}
    </Box>
  );
}

export default Location;
