import React from "react";
import { Box } from "@mui/material";
import SublineInfo from "../components/SublineInfo";
import SectionTitle from "../components/SectionTitle";
import DescriptionSection from "../components/DescriptionSection";
import SectionDivider from "../components/SectionDivider";
import { useTranslation } from "react-i18next";

function Übernachten() {
  const { t } = useTranslation();
  const accommodation_subline = t("accommodation_subline");
  const accommodation_headline = t("accommodation_headline");
  const accommodation_hint = t("accommodation_hint");
  const accommodation_transport_info = t("accommodation_transport_info");

  return (
    <Box
      sx={{
        flexGrow: 1,

        px: { xs: 1, md: 12 }, // Adjust padding as needed
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SublineInfo title={accommodation_subline} />
      <SectionTitle title={accommodation_headline} imageSrc="/Bekond.webp" />
      <SectionDivider />
      <DescriptionSection description={accommodation_hint} />
      <SectionDivider height={"1rem"} />
      <DescriptionSection description={accommodation_transport_info} />

      {/* Additional content can be added here if needed */}
    </Box>
  );
}

export default Übernachten;
