import React from "react";
import { Box } from "@mui/material";
import SublineInfo from "../components/SublineInfo";
import SectionTitle from "../components/SectionTitle";
import DescriptionSection from "../components/DescriptionSection";
import SectionDivider from "../components/SectionDivider";
import AutoFixOffIcon from "@mui/icons-material/AutoFixOff"; // Import the first icon
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { useTranslation } from "react-i18next";

function ZuBeachten() {
  const { t } = useTranslation();
  const request_subline = t("request_subline");
  const request_headline = t("request_headline");
  const request_hint_first = t("request_hint_first");
  const request_hint_second = t("request_hint_second");
  return (
    <Box
      sx={{
        flexGrow: 1,
        px: { xs: 1, md: 12 }, // Adjust padding as needed
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SublineInfo title={request_subline} />
      <SectionTitle title={request_headline} imageSrc="/orangerie.webp" />
      <DescriptionSection
        description={
          <>
            <AutoFixOffIcon /> {request_hint_first}
          </>
        }
      />
      <SectionDivider borderColor="orange" />
      <DescriptionSection
        description={
          <>
            <SentimentVeryDissatisfiedIcon /> {request_hint_second}
          </>
        }
      />
      {/* Additional content can be added here if needed */}
    </Box>
  );
}

export default ZuBeachten;
