import React from "react";
import { Box, Typography } from "@mui/material";

const SublineInfo = ({ title }) => {
  // Accepting title as a prop
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: { xs: "end", md: "center" },
        px: { md: 2, lg: 24, xl: 36 },
        zIndex: 30,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: 2,
          pl: { xs: 12, md: 32 },
          zIndex: 20,
        }}
      >
        <Typography
          sx={{
            flexShrink: 1,
            color: "#4b5563",
            fontSize: { xs: "small", md: "large" },
            minWidth: "max-content",
            fontFamily: "Cormorant Garamond, serif",
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            borderTop: "1px solid #4b5563",
            maxWidth: { md: 480 },
          }}
        />
      </Box>
    </Box>
  );
};

export default SublineInfo;
