import React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <ButtonGroup
      variant="outlined"
      size="small"
      aria-label="outlined button group"
      sx={{
        backgroundColor: "#324448", // Set the background color
        "& .MuiButton-root": {
          color: "white",
          borderColor: "transparent", // Remove default border color
          "&:hover": {
            backgroundColor: "#1f2f30", // Change button background color on hover
            borderColor: "transparent", // Keep border transparent on hover
          },
        },
      }}
    >
      <Button
        onClick={() => changeLanguage("de")}
        style={{
          backgroundColor: currentLanguage === "de" ? "transparent" : "#f0f0f0",
          color: currentLanguage === "de" ? "white" : "inherit",
        }}
      >
        DE
      </Button>
      <Button
        onClick={() => changeLanguage("en")}
        style={{
          backgroundColor: currentLanguage === "en" ? "transparent" : "#f0f0f0",
          color: currentLanguage === "en" ? "white" : "inherit",
        }}
      >
        EN
      </Button>
    </ButtonGroup>
  );
};

export default LanguageSwitcher;
