import React from "react";
import { Typography, Link, Button, Grid, Stack } from "@mui/material";

const AddressSection = ({
  addressTitle,
  street,
  zipCode,
  firstLink,
  firstButtonText,
  secondLink,
  secondButtonText,
  thirdLink,
  thirdButtonText,
}) => {
  return (
    <Grid container spacing={0.5} id="where">
      <Grid item xs={2.5} md={2} lg={1}>
        <Typography
          sx={{
            fontSize: { xs: "0.9rem", md: "1.4rem" },
            fontFamily: "Cormorant Garamond, serif",
            fontWeight: 500,
          }}
        >
          {addressTitle}
        </Typography>
      </Grid>
      <Grid item xs={9.5} md={10} lg={11}>
        <Link
          href={firstLink}
          id="address_container"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            pb: 2,
            color: "black",
            textDecoration: "none",
            display: "block",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "1.2rem", md: "1.6rem" },
              fontFamily: "Cormorant Garamond, serif",
            }}
          >
            {street}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "1.2rem", md: "1.6rem" },
              fontFamily: "Cormorant Garamond, serif",
            }}
          >
            {zipCode}
          </Typography>
        </Link>
        <Stack
          direction={{ xs: "column", sm: "row" }} // Vertical stack on xs, horizontal on md and up
          spacing={1} // Adjust the space between items
        >
          {firstButtonText && (
            <Button
              href={firstLink}
              target="_blank"
              size="large"
              rel="noopener noreferrer"
              variant="outlined"
              sx={{
                mt: 2,
                color: "#324448",
                borderColor: "#324448", // Set the background color
                "&:hover": {
                  color: "#2b3a3b",
                  borderColor: "#2b3a3b", // Optional: change color when the button is hovered over
                },
              }}
            >
              {firstButtonText}
            </Button>
          )}
          {secondButtonText && (
            <Button
              href={secondLink}
              target="_blank"
              rel="noopener noreferrer"
              variant="outlined"
            >
              {secondButtonText}
            </Button>
          )}
          {thirdButtonText && (
            <Button
              href={thirdLink}
              target="_blank"
              rel="noopener noreferrer"
              variant="outlined"
            >
              {thirdButtonText}
            </Button>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AddressSection;
