import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function PasswordProtection({ children }) {
  const { t } = useTranslation();

  const PASSWORD = process.env.REACT_APP_PASSWORD;
  const password_headline = t("password_headline");
  const password_welcome_message = t("password_welcome_message");
  const password_input_placeholder = t("password_input_placeholder");
  const password_button = t("password_button");

  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handlePasswordSubmit = (event) => {
    event.preventDefault();

    if (password === PASSWORD) {
      setIsAuthenticated(true);
    } else {
      alert("Incorrect password");
    }
  };

  const handleClickShowPassword = (event) => {
    event.preventDefault(); // prevent the default action
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  if (isAuthenticated) {
    return children;
  }

  return (
    <Box
      sx={{
        position: "relative",
        height: "100vh",
        "&:before": {
          content: '""',
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundImage: "url(/background-min.webp)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          opacity: 0.4,
          zIndex: -1,
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 20,
          right: 20,
          p: 2,
          zIndex: 2,
        }}
      >
        <LanguageSwitcher />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center", // Change this from 'flex-start' to 'center'
          minHeight: "100vh",
          zIndex: 1,
          px: { xs: 2, lg: 42 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start", // Keep items inside the box aligned to the left
            width: "fit-content", // Add this to keep the width of the box to the content
          }}
        >
          <Typography
            sx={{ fontFamily: "Cormorant Garamond, serif" }}
            variant="h4"
            color="textPrimary"
            gutterBottom
            align="left" // Add this to align the text to the left
          >
            {password_welcome_message}
          </Typography>
          <Typography
            sx={{ fontFamily: "Cormorant Garamond, serif" }}
            variant="h6"
            color="textPrimary"
            gutterBottom
            maxWidth={"420px"}
            align="left" // Add this to align the text to the left
          >
            {password_headline}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start", // Keep items inside the box aligned to the left
              width: "fit-content", // Add this to keep the width of the box to the content
            }}
          >
            <form onSubmit={handlePasswordSubmit}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left", // Align items in the center
                  width: "100%", // Take the full width
                }}
              >
                <FormControl
                  sx={{ ml: 0, mb: 2, width: "36ch" }}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    {password_input_placeholder}
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    variant="outlined"
                    required
                    name="password"
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={handlePasswordChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={password_input_placeholder}
                  />
                </FormControl>

                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#324448",
                    mr: { md: 2 }, // Add right margin on medium screens and up
                    width: { md: "auto" }, // Adjust width on medium screens and up
                    height: "40px",
                    "&:hover": {
                      backgroundColor: "#2b3a3b",
                    },
                  }}
                >
                  {password_button}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default PasswordProtection;
