import { Link as RouterLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import MeineAntwortButton from "../components/MeineAntwortButton";
import { useTranslation } from "react-i18next";

function AnneFabian() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [topSpacing, setTopSpacing] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    // Function to get height of AppBar
    const getAppBarHeight = () => {
      const appBar = document.querySelector(".MuiAppBar-root");
      return appBar ? appBar.clientHeight : 0;
    };

    // Set top spacing on mount and when window is resized
    const handleResize = () => {
      setTopSpacing(getAppBarHeight());
    };

    // Set initial top spacing
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures effect only runs on mount and unmount

  return (
    <Box
      sx={{
        flexGrow: 1,
        mt: `-${topSpacing}px`,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Typography
        sx={{ fontFamily: "Cormorant Garamond, serif" }}
        variant="h4"
        color="textPrimary"
        gutterBottom
      >
        {t("welcome_message")}
      </Typography>
      <Typography
        sx={{ fontFamily: "Cormorant Garamond, serif" }}
        variant="h2"
        color="textPrimary"
        gutterBottom
      >
        Anne & Fabian
      </Typography>
      <Typography
        sx={{ fontFamily: "Cormorant Garamond, serif" }}
        variant="h3"
        color="textPrimary"
        gutterBottom
      >
        03 August 2024
      </Typography>
      <RouterLink
        to="/location"
        style={{
          textDecoration: "none",
          color: theme.palette.text.primary,
          fontFamily: "Cormorant Garamond, serif",
          fontSize: "1.5rem",
          marginBottom: "1rem",
        }}
      >
        Orangerie Schloß Bekond
      </RouterLink>
      {isMobile && <MeineAntwortButton />}
    </Box>
  );
}

export default AnneFabian;
