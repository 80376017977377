import React from "react";
import { Typography, Grid, Box, Hidden } from "@mui/material";

const DescriptionSection = ({
  timeElement,
  mainTitle,
  subTitle,
  description,
  imageSrc,
}) => {
  return (
    <Grid container spacing={0.5} id="description">
      {/* Conditional rendering for timeElement and mainTitle */}
      {(timeElement || mainTitle) && (
        <Grid item container spacing={0.5} alignItems="center">
          {/* Conditional rendering for the timeElement */}
          {timeElement && (
            <Grid item xs={2.5} md={2} lg={1}>
              <Typography
                variant="h3"
                sx={{
                  fontSize: { xs: "1.4rem", md: "1.8rem" },
                  fontFamily: "Cormorant Garamond, serif",
                  fontWeight: 500,
                }}
              >
                {timeElement}
              </Typography>
            </Grid>
          )}
          {/* Conditional rendering for the mainTitle */}
          {mainTitle && (
            <Grid item xs={9.5} md={10} lg={11}>
              <Typography
                sx={{
                  fontSize: { xs: "1.4rem", md: "1.8rem" },
                  fontFamily: "Cormorant Garamond, serif",
                  maxWidth: "42rem",
                }}
              >
                {mainTitle}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}

      {/* Conditional rendering for subTitle */}
      {subTitle && (
        <Grid item xs={2.5} md={2} lg={1}>
          <Typography
            sx={{
              fontSize: { xs: "0.9rem", md: "1.6rem" },
              fontFamily: "Cormorant Garamond, serif",
              fontWeight: 500,
            }}
          >
            {subTitle}
          </Typography>
        </Grid>
      )}

      {/* Conditional rendering for description */}
      {description && (
        <Grid item xs={9.5} md={7} lg={8}>
          <Typography
            sx={{
              fontSize: { xs: "1.2rem", md: "1.6rem" },
              fontFamily: "Cormorant Garamond, serif",
              maxWidth: "42rem",
            }}
          >
            {description}
          </Typography>
        </Grid>
      )}

      {/* Conditional rendering for imageSrc */}
      {imageSrc && (
        <Hidden mdDown>
          <Grid item xs={12} md={3} lg={3} style={{ position: "relative" }}>
            <Box
              sx={{
                borderRadius: "50%",
                overflow: "hidden",
                width: "70%", // Decreased from 60% to 30%
                paddingTop: "70%", // Decreased from 60% to 30%
                position: "absolute",
                right: "12rem",
                top: "-110px",
                zIndex: 0,
                opacity: 0.4,
              }}
            >
              <img
                src={imageSrc}
                alt="Decorative"
                loading="lazy"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              />
            </Box>
          </Grid>
        </Hidden>
      )}
    </Grid>
  );
};

export default DescriptionSection;
