import React, { useState } from "react";

import {
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useTranslation, Trans } from "react-i18next";

function TransitionLeft(props) {
  return <Slide {...props} direction="right" />;
}

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    attendance: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const handleRadioChange = (event) => {
    setFormData({ ...formData, attendance: event.target.value });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const API_URL =
        process.env.NODE_ENV === "production"
          ? "https://www.anne-und-fabian.de"
          : "http://localhost:5001";

      const response = await fetch(`${API_URL}/api/server`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setFormData({
          name: "",
          email: "",
          phone: "",
          attendance: "",
          message: "",
        });
        setSnackbar({
          open: true,
          message: t("snackbar_success_message"),
          severity: "success",
        });
      } else {
        setSnackbar({
          open: true,
          message: t("snackbar_error_message"),
          severity: "error",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setSnackbar({
        open: true,
        message: "An unexpected error occurred",
        severity: "error",
      });
    }
    setIsLoading(false);
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const autofillStyle = {
    WebkitBoxShadow: "0 0 0 1000px white inset",
    WebkitTextFillColor: "#000",
  };

  const { t } = useTranslation();
  const rsvp_hint_headline = t("rsvp_hint_headline");
  const rsvp_contact_form_name = t("rsvp_contact_form_name");
  const rsvp_contact_form_email = t("rsvp_contact_form_email");
  const rsvp_contact_form_phone = t("rsvp_contact_form_phone");
  const rsvp_contact_form_attendance = t("rsvp_contact_form_attendance");
  const rsvp_contact_form_attendance_single_select_one = t(
    "rsvp_contact_form_attendance_single_select_one"
  );
  const rsvp_contact_form_attendance_single_select_two = t(
    "rsvp_contact_form_attendance_single_select_two"
  );
  const rsvp_contact_form_message = t("rsvp_contact_form_message");
  const rsvp_contact_form_button = t("rsvp_contact_form_button");

  return (
    <Box sx={{ flexGrow: 1, m: 2 }} component="form" onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography
              sx={{
                fontSize: "2rem",
                fontFamily: "Cormorant Garamond, serif",
                maxWidth: "42rem",
              }}
            >
              {rsvp_hint_headline}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "1.2rem", md: "1.6rem" },
                fontFamily: "Cormorant Garamond, serif",
                maxWidth: "42rem",
              }}
            >
              {/* Render RSVP hint with HTML */}
              <Trans i18nKey="rsvp_hint" components={{ strong: <strong /> }} />
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box>
            <TextField
              fullWidth
              label={rsvp_contact_form_name}
              variant="outlined"
              sx={{ mb: 2 }}
              required
              name="name"
              value={formData.name}
              onChange={handleChange}
              InputProps={{
                style: autofillStyle,
              }}
            />
            <TextField
              fullWidth
              label={rsvp_contact_form_email}
              type="email"
              variant="outlined"
              sx={{ mb: 2 }}
              required
              name="email"
              value={formData.email}
              onChange={handleChange}
              InputProps={{
                style: autofillStyle,
              }}
            />
            <TextField
              fullWidth
              label={rsvp_contact_form_phone}
              variant="outlined"
              sx={{ mb: 2 }}
              required
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              InputProps={{
                style: autofillStyle,
              }}
            />
            <FormControl component="fieldset" sx={{ mb: 2 }}>
              <FormLabel component="legend">
                {rsvp_contact_form_attendance} *
              </FormLabel>
              <RadioGroup
                row
                name="attendance"
                value={formData.attendance}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio required />}
                  label={rsvp_contact_form_attendance_single_select_one}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio required />}
                  label={rsvp_contact_form_attendance_single_select_two}
                />
              </RadioGroup>
            </FormControl>
            <TextField
              fullWidth
              label={rsvp_contact_form_message}
              multiline
              rows={4}
              variant="outlined"
              sx={{ mb: 2 }}
              name="message"
              value={formData.message}
              onChange={handleChange}
              InputProps={{
                style: autofillStyle,
              }}
            />
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#324448",
                width: "150px", // Adjust the width as needed
                height: "40px", // You might need to adjust the height to match your design
                "&:hover": {
                  backgroundColor: "#2b3a3b",
                },
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} style={{ color: "#FFFFFF" }} />
              ) : (
                rsvp_contact_form_button
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        TransitionComponent={TransitionLeft}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default ContactForm;
