import React from "react";
import { Box, Typography } from "@mui/material";

const SectionTitle = ({ title, imageSrc }) => {
  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        pl: "2rem",
        pb: "4rem",
      }}
      id="headline_container"
    >
      <Typography
        sx={{
          fontFamily: "Cormorant Garamond, serif",
          fontSize: { xs: "3rem", md: "4rem" },
          zIndex: 10,
          top: 20,
          position: "relative", // Ensure the zIndex takes effect
        }}
        variant="h1"
        color="textPrimary"
      >
        {title}
      </Typography>

      {imageSrc && (
  <Box
    sx={{
      borderRadius: "50%",
      overflow: "hidden",
      width: { xs: 180, md: 360 },
      height: { xs: 180, md: 360 },
      position: "absolute",
      right: { xs: "2rem", md: "8rem", lg: "18rem" },
      top: 20,
      zIndex: -1, // Set the z-index to a negative value
      opacity: { xs: 0.4, md: 1 },
    }}
  >
    <img
      src={imageSrc}
      alt="Decorative"
      loading="lazy"
      style={{
        width: "100%",
        height: "100%",
      }}
    />
  </Box>
)}
    </Box>
  );
};

export default SectionTitle;
