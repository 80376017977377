import React from "react";
import Box from "@mui/material/Box";

const SectionDivider = ({ borderColor = "transparent", height = "5rem" }) => {
  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      <Box sx={{ height: height, display: "flex", alignItems: "center" }}>
        <Box
          sx={{ borderTop: 0.5, borderColor: borderColor, width: "100%" }}
        ></Box>
      </Box>
    </Box>
  );
};

export default SectionDivider;
