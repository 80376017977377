import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

function MeineAntwortButton({ handleDrawerToggle }) {
  const { t } = useTranslation();
  return (
    <Button
      size="large"
      variant="contained"
      component={Link}
      to="/meine-antwort"
      onClick={handleDrawerToggle}
      sx={{
        mr: 2,
        backgroundColor: "#324448", // Set the background color
        "&:hover": {
          backgroundColor: "#2b3a3b", // Optional: change color when the button is hovered over
        },
        // Add more styles as needed
      }}
    >
      {t("rsvp_button")}
    </Button>
  );
}

export default MeineAntwortButton;
