import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navigation from "./components/Navigation";
import AnneFabian from "./views/AnneFabian";
import Location from "./views/Location";
import Ablauf from "./views/Ablauf";
import Übernachten from "./views/Übernachten";
import MeineAntwort from "./views/MeineAntwort";
import ZuBeachten from "./views/ZuBeachten";
import { Box } from "@mui/material";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n"; // path to your i18n config
import PasswordProtection from "./components/PasswordProtection";

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <PasswordProtection>
          {/* Outer Box for Background */}
          <Box
            sx={{
              position: "relative", // Add this line
              minHeight: "100vh",
              "&:before": {
                // Pseudo-element for the background
                content: '""',
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundImage: "url(/background-min.webp)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                opacity: 0.4, // Adjust the opacity as needed
                zIndex: -1, // Ensure the content is above the background
              },
            }}
          >
            <Navigation />
            {/* Place the LanguageSwitcher here or anywhere you prefer */}
            <Box
              sx={{
                pt: "4rem",
                pb: "8rem",
                position: "relative", // Ensure this content is above the background
                zIndex: 1, // Ensure this content is above the background
              }}
            >
              <Switch>
                <Route exact path="/" component={AnneFabian} />
                <Route path="/location" component={Location} />
                <Route path="/ablauf" component={Ablauf} />
                <Route path="/übernachten" component={Übernachten} />
                <Route path="/zu-beachten" component={ZuBeachten} />
                <Route path="/meine-antwort" component={MeineAntwort} />
                {/* Add more routes as needed */}
              </Switch>
            </Box>
          </Box>
        </PasswordProtection>
      </Router>
    </I18nextProvider>
  );
}

export default App;
