import React from "react";
import { Box } from "@mui/material";
import SublineInfo from "../components/SublineInfo";
import SectionTitle from "../components/SectionTitle";
import DescriptionSection from "../components/DescriptionSection";
import SectionDivider from "../components/SectionDivider";
import { useTranslation } from "react-i18next";

function Ablauf() {
  const { t } = useTranslation();

  const schedule_subline = t("schedule_subline");
  const schedule_headline = t("schedule_headline");
  const schedule_main_time_first = t("schedule_main_time_first");
  const schedule_main_title_first = t("schedule_main_title_first");
  const schedule_sub_title_first = t("schedule_sub_title_first");
  const schedule_sub_title_description_frist = t(
    "schedule_sub_title_description_frist"
  );
  const schedule_main_time_second = t("schedule_main_time_second");
  const schedule_main_title_second = t("schedule_main_title_second");
  const schedule_sub_title_second = t("schedule_sub_title_second");
  const schedule_sub_title_description_second = t(
    "schedule_sub_title_description_second"
  );
  const schedule_main_time_third = t("schedule_main_time_third");
  const schedule_main_title_third = t("schedule_main_title_third");
  const schedule_sub_title_third = t("schedule_sub_title_third");
  const schedule_sub_title_description_third = t(
    "schedule_sub_title_description_third"
  );
  const schedule_main_time_fourth = t("schedule_main_time_fourth");
  const schedule_main_title_fourth = t("schedule_main_title_fourth");
  const schedule_sub_title_fourth = t("schedule_sub_title_fourth");
  const schedule_sub_title_description_fourth = t(
    "schedule_sub_title_description_fourth"
  );
  const schedule_main_time_fifth = t("schedule_main_time_fifth");
  const schedule_main_title_fifth = t("schedule_main_title_fifth");
  const schedule_sub_title_fifth = t("schedule_sub_title_fifth");
  const schedule_sub_title_description_fifth = t(
    "schedule_sub_title_description_fifth"
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        px: { xs: 1, md: 12 }, // Adjust padding as needed
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SublineInfo title={schedule_subline} />
      <SectionTitle title={schedule_headline} />
      <SectionDivider />
      <DescriptionSection
        timeElement={schedule_main_time_first}
        mainTitle={schedule_main_title_first}
        subTitle={schedule_sub_title_first}
        description={schedule_sub_title_description_frist}
        imageSrc="/freie-trauung.webp"
      />
      <SectionDivider
        borderColor="orange"
        height={{ xs: "5rem", md: "10rem" }}
      />
      <DescriptionSection
        timeElement={schedule_main_time_second}
        mainTitle={schedule_main_title_second}
        subTitle={schedule_sub_title_second}
        description={schedule_sub_title_description_second}
        imageSrc="/schloss.png"
      />
      <SectionDivider
        borderColor="orange"
        height={{ xs: "5rem", md: "10rem" }}
      />
      <DescriptionSection
        timeElement={schedule_main_time_third}
        mainTitle={schedule_main_title_third}
        subTitle={schedule_sub_title_third}
        description={schedule_sub_title_description_third}
        imageSrc="/abendessen.jpeg"
      />
      <SectionDivider
        borderColor="orange"
        height={{ xs: "5rem", md: "12rem" }}
      />
      <DescriptionSection
        timeElement={schedule_main_time_fourth}
        mainTitle={schedule_main_title_fourth}
        subTitle={schedule_sub_title_fourth}
        description={schedule_sub_title_description_fourth}
        imageSrc="/party.jpeg"
      />
      <SectionDivider
        borderColor="orange"
        height={{ xs: "5rem", md: "12rem" }}
      />
      <DescriptionSection
        timeElement={schedule_main_time_fifth}
        mainTitle={schedule_main_title_fifth}
        subTitle={schedule_sub_title_fifth}
        description={schedule_sub_title_description_fifth}
      />
    </Box>
  );
}

export default Ablauf;
